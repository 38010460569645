import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"./logo.png"} className="App-logo" alt="logo" />
        <p>CGMACODER</p>
        <p>coming soon!</p>
      </header>
    </div>
  );
}

export default App;
